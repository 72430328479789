<template>
  <div class="card p-10">
    <div class="d-flex justify-content-end">
      <div>
        <button
          @click="dialogVisible = true"
          type="button"
          class="btn btn-success me-3"
        >
          新增項目
        </button>
        <button
          @click="handelAddDeviceMappin()"
          type="button"
          class="btn btn-primary"
        >
          送出新增項目
        </button>
      </div>
    </div>
    <Datatable
      :table-data="tableData"
      :table-header="tableHeader"
      :enable-items-per-page-dropdown="true"
      :openTextEnd="false"
      :striped="true"
    >
      <template v-slot:cell-device="{ row: tableData }">
        {{ tableData.device }}
      </template>
      <template v-slot:cell-targetType="{ row: tableData }">
        {{ targetTypeMap[tableData.targetType]}}
      </template>
      <template v-slot:cell-target="{ row: tableData }">
        {{ tableData.target }}
      </template>
      <template v-slot:cell-operate="{ row: tableData }">
        <el-button @click="deleteItem(tableData.id)" type="danger"
          >刪除</el-button
        >
      </template>
    </Datatable>
  </div>
  <Dialog append-to-body title="新增項目" v-model="dialogVisible" width="45%">
    <el-form
      label-position="left"
      :model="formData"
      :rules="rules"
      ref="formRef"
      class="form"
      label-width="110px"
    >
      <el-form-item label="目標類型" prop="targetType">
        <el-select style="width: 100%" v-model="formData.targetType" filterable>
          <el-option
            v-for="item in targetTypeOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="目標" prop="target">
        <el-select
          v-if="formData.targetType == 'Facility'"
          v-model="formData.target"
          filterable
          placeholder="請選擇目標"
          style="width: 100%"
        >
          <el-option
            v-for="item in facilitiesOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-cascader
          v-else
          filterable
          v-model="formData.target"
          :options="facilityUnitOption"
          placeholder="請選擇目標"
        />
      </el-form-item>
      <el-form-item label="裝置" prop="device">
        <el-select
          v-model="formData.device"
          multiple
          filterable
          placeholder="請選擇裝置"
          style="width: 100%"
        >
          <el-option
            v-for="item in deviceOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <button @click="onSubmit()" type="button" class="btn btn-primary">
        新增
      </button>
    </template>
  </Dialog>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref } from "vue";
import { addDeviceMappingBySpace } from "@/core/services/api/deviceMapping";
import { getFacilityBySpace } from "@/core/services/api/facilities";
import localstorage from "@/core/services/LocalstorageService";
import { getFacilityUnitByFacilities } from "@/core/services/api/facilityUnits";
import { getDevices } from "@/core/services/api/device";
import Dialog from "@/components/v-dialog/CustomizeDialog.vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Dialog,
    Datatable,
  },
  setup() {
    const router = useRouter()
    const formRef = ref<null | HTMLFormElement>(null);
    const formData = reactive({
      targetType: "Facility",
      target: "",
      device: [],
    });
    const rules = {
      targetType: [
        { required: true, message: "請選擇目標類型", trigger: "change" },
      ],
      target: [{ required: true, message: "請選擇目標", trigger: "change" }],
      device: [{ required: true, message: "請選擇裝置", trigger: "change" }],
    };
    const targetTypeOption: any = reactive([
      {
        label: "設施",
        value: "Facility",
      },
      {
        label: "座位",
        value: "FacilityUnit",
      },
    ]);
    const targetTypeMap = {
      Facility: "設施",
      FacilityUnit: "座位",
    };
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const facilitiesOption: any = reactive([]);
    const facilities: any = reactive([]);
    const devices: any = reactive([]);
    const facilityUnits: any = reactive([]);
    const facilityUnitOption: any = reactive([]);
    const deviceOption: any = reactive([]);
    const tableData: any = reactive([]);
    const tableHeader = ref([
      {
        name: "裝置",
        key: "device",
        sortable: true,
      },
      {
        name: "目標類型",
        key: "targetType",
        sortable: true,
      },
      {
        name: "目標",
        key: "target",
        sortable: true,
      },

      {
        name: "功能",
        key: "operate",
        sortable: true,
      },
    ]);
    const dialogVisible = ref(false);

    const setFacilitiesOption = async () => {
      let response = await getFacilityBySpace(currentSpaceGuid!);
      response = response.filter(o => o.facility.type == "Facility")
      console.log("response",response.filter(o => o.facility.name == "測試特殊通行權限"))
      facilities.splice(0, facilities.length, ...response);
      facilitiesOption.splice(0);
      for (const item of response) {
        facilitiesOption.push({
          label: item.facility.name,
          value: item.facility.guid,
        });
      }
    };

    const setFacilityUnitOption = async () => {
      let facilitiesGuid = facilities.map((o) => o.facility.guid);
      let request = { facilities: facilitiesGuid };
      let facilityUnitList = await getFacilityUnitByFacilities(request);
      facilityUnits.splice(0, facilityUnits.length, ...facilityUnitList);
      facilityUnitOption.splice(0);
      
      for (const facility of facilities) {
        facilityUnitOption.push({
          value: facility.facility.guid,
          label: facility.facility.name,
          children: [],
        });
      }
      for (const facilityUnits of facilityUnitList) {
        let optionFilter = facilityUnitOption.filter(
          (o) => o.value == facilityUnits.facility
        )[0];
        optionFilter.children.push({
          value: facilityUnits.guid,
          label: facilityUnits.name,
        });
      }
facilityUnitOption.splice(0,facilityUnitOption.length,...facilityUnitOption.filter(o=>o.children.length>0))
    };

    const setDeviceOption = async () => {
      let response = await getDevices(currentSpaceGuid);
      devices.splice(0, devices.length, ...response);
      deviceOption.splice(0);
      for (const item of response) {
        deviceOption.push({
          label: item.name,
          value: item.guid,
        });
      }
    };

    const getTargetName = (targetType, guid) => {
      if (targetType == "Facility") {
        return facilities.filter((o) => o.facility.guid == guid)[0].facility
          .name;
      } else if (targetType == "FacilityUnit") {
        return `${
          facilities.filter((o) => o.facility.guid == guid[0])[0].facility.name
        } -> ${facilityUnits.filter((o) => o.guid == guid[1])[0].name}`;
      }
    };

    const getTargetGuid = (targetType, guid) => {
      if (targetType == "Facility") {
        return guid;
      } else if (targetType == "FacilityUnit") {
        return guid[1];
      }
    };

    const getDeviceByGuid = (guid) => {
      let device = devices.filter((o) => o.guid == guid)[0];
      return device;
    };

    const onSubmit = () => {
      formRef.value!.validate(async (valid) => {
        if (valid) {
          for (const item of formData.device) {
            tableData.push({
              dataGuid: item,
              formData: formData,
              id: Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
              // targetType: targetTypeMap[formData.targetType],
              targetType: formData.targetType,
              target: getTargetName(formData.targetType, formData.target),
              targetGuid: getTargetGuid(formData.targetType, formData.target),
              device: getDeviceByGuid(item).name,
            });
          }

          Object.assign(formData, {
            targetType: "Facility",
            target: "",
            device: [],
          });
          formRef.value!.resetFields();
          dialogVisible.value = false;
        }
      });
    };

    const handelAddDeviceMappin = async () => {
      await Swal.fire({
        title: "您確定要送出新增項目嗎?",
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let request: any = [];
          for (const item of tableData) {
            request.push({
              device: item.dataGuid,
              targetType: item.targetType,
              target: item.targetGuid,
            });
              //console.log('item',item)
          }
          //console.log('request', request)
          
          await addDeviceMappingBySpace(currentSpaceGuid, request).then(
            async () => {
              await Swal.fire("新增成功!", "", "success");
              router.push({name:"IoTMappingList"})
            }
          );
          
        }
      });
    };

    const deleteItem = (id) => {
      let tableDataFilter = tableData.filter((o) => o.id !== id);
      tableData.splice(0, tableData.length, ...tableDataFilter);
    };

    const init = async () => {
      await setFacilitiesOption();
      await setFacilityUnitOption();
      await setDeviceOption();
    };
    init();

    return {
      formRef,
      formData,
      targetTypeOption,
      facilitiesOption,
      facilityUnitOption,
      deviceOption,
      tableData,
      tableHeader,
      dialogVisible,
      rules,
      onSubmit,
      deleteItem,
      handelAddDeviceMappin,
      targetTypeMap
    };
  },
});
</script>

<style>
</style>